<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-flex  justify-content-end flex-wrap mb-2">
          <b-button type="button" variant="primary" @click="getFile">
            <feather-icon icon="DownloadIcon" size="14" />
            <span> Download </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <AppTable
      :rows="rows"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: newsletter.limit,
        count: newsletter.count,
        page: newsletter.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { format } from 'date-fns'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'
import { downloadFile } from '@/tools/helpers'

export default {
  name: 'NewsletterView',
  components: {
    AppTable,
    BButton,
    BRow,
    BCol,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
    }
  },

  computed: {
    ...mapGetters({
      newsletter: 'newsletter/newsletter',
    }),

    rows() {
      return this.transformNewsletterData(this.newsletter?.value || [])
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchNewsletter: 'newsletter/fetchNewsletter',
      fetchFile: 'newsletter/fetchFile',
    }),

    formatDate(timestamp) {
      return format(new Date(timestamp), 'dd.MM.yyyy HH:mm:ss')
    },

    initState() {
      this.getNewsletter()
    },

    getFile() {
      this.waitRequest(() => {
        return this.fetchFile()
          .then(response => downloadFile(response.data, 'email.csv'))
          .catch(this.checkErrors)
      })
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getNewsletter(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getNewsletter(query)
    },

    createQueryConfig(config) {
      return {
        page: this.newsletter.page,
        limit: this.newsletter.limit,
        search: this.searchTerm,

        ...config,
      }
    },

    getNewsletter(query = {}) {
      return this.waitRequest(() => {
        return this.fetchNewsletter(query).catch(this.checkErrorsAlert)
      })
    },

    transformNewsletterData(rows) {
      return rows.map(rowData => {
        const createdAt = this.formatDate(rowData.createdAt)

        return {
          ...rowData,
          createdAt,
        }
      })
    },
  },
}
</script>
