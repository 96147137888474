export default [
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'Created',
    field: 'createdAt',
  },
]
